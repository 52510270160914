











import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { View } from 'client-website-ts-library/plugins';
import { ListingStatus, MethodOfSale } from 'client-website-ts-library/types';

import Listings from '../components/Listings.vue';

@Component({
  components: {
    Listings,
  },
})
export default class PropertiesForSale extends Mixins(View) {
  private filter: ListingFilter = new ListingFilter({
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    MethodsOfSale: [MethodOfSale.Auction],
  });

  handleFilter(filter: ListingFilter) {
    // eslint-disable-next-line no-param-reassign
    filter.OrderByStatements = [ListingOrderBy.CreateDateDesc];
    this.filter = filter;
  }
}
